<div class="avatar-container">

  <ng-container *ngrxLet="selectedPatient$ as patient">

    <div class="title">{{ patient.nomeHospede }} | 5o-{{ patient.quarto }}</div>

    <div class="avatar">
      <img alt="image" class="img-circle" [class.miniImg]="showMiniImg" width="76" src="{{ patient.foto }}"
        (error)="swapImage($event.target, patient)">
    </div>

  </ng-container>

</div>