import { Injectable } from '@angular/core';
import { FhirGenericService } from './fhir-generic.service';
import { routeActivityDetails } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirActivityDetailService extends FhirGenericService {

  protected url = routeActivityDetails;


}
