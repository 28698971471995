<div class="container">

  <button type="button" mat-button (click)="onBackClick($event)">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>

  <ml-ui-care-plan-actitivy-detail [activity]="activity$ | async" [performerPractitioner]="selectedPractitioner$ | async" [practitioners]="practitioners$ | async"
    (updateValues)="onUpdateValues($event)" [activityType]="activityType$ | async" (confirm)="onConfirm($event)" [fields]="fields$ | async"></ml-ui-care-plan-actitivy-detail>

</div>
