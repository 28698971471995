import { FhirPatientIconsService, FhirPatientService, loadActivities, loadPractitioners, selectActivitiesExtraByShift, selectActivitiesPlannedByShift, selectedShift } from '@medlogic/fhir';
import { selectedActivityModeTitle, setActivityMode } from '@medlogic/fhir';
import { selectedActivityMode } from '@medlogic/fhir';
import { EnActivityMode, EnShift, IActivity, IShift, IActionEmitter, IProductCodeablePatientIcons, UnsubscribeOnDestroyAdapter, IFhirPatient, IProductCodeableConcept } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IAppMedlogicFhirState } from '@medlogic/fhir';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, of, tap, toArray } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { selectedPatientId } from '@medlogic/medlogic/medlogic-state';

@Component({
  selector: 'ml-ui-care-plan',
  templateUrl: './ui-care-plan.component.html',
  styleUrls: ['./ui-care-plan.component.css']
})
export class UiCarePlanComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Output() menuItemClick = new EventEmitter<string>();
  @Output() activityClick = new EventEmitter<IActivity>();
  @Output() actionClick = new EventEmitter<IActionEmitter>();

  activityMode$: Observable<EnActivityMode> = this.store.pipe(select(selectedActivityMode));
  activityModeTitle$: Observable<string> = this.store.pipe(select(selectedActivityModeTitle));
  activitiesByShift$: Observable<IShift[]> = this.store.pipe(select(selectActivitiesPlannedByShift));
  activitiesExtraByShift$: Observable<IShift[]> = this.store.pipe(select(selectActivitiesExtraByShift));
  selectedShift$: Observable<EnShift> = this.store.pipe(select(selectedShift));

  patientIcons$ = new Observable<any>();

  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  ENACTIVITYMODE = EnActivityMode;

  patientId: string;

  constructor(
    private log: LogService,
    private store: Store<IAppMedlogicFhirState>,
    private http: HttpClient,
    private patientSrv: FhirPatientService,
    private patientIconsSrv: FhirPatientIconsService,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.store.dispatch(loadActivities());
      this.store.dispatch(loadPractitioners());
      this.loadActivityIcons();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onMenuItemClick(option: string): void {
    try {
      switch (option?.toLowerCase() || '') {
        case EnActivityMode.CarePlan:
          this.store.dispatch(setActivityMode({ selectedActivityMode: EnActivityMode.CarePlan }));
          break;
        case EnActivityMode.Extra:
          this.store.dispatch(setActivityMode({ selectedActivityMode: EnActivityMode.Extra }));
          break;
        default:
          break;
      }
      this.menuItemClick.emit(option);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onMenuItemClick', error.message);
    }
  }

  onActivityClick(activity: IActivity): void {
    try {
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  onActionClick({ actionType, data }: IActionEmitter): void {
    try {
      this.actionClick.emit({ actionType, data });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }

  getPatientIdentifier() : Observable<any>{
    try{
  return this.selectedPatientId$
    .pipe(
      mergeMap(
        selectedPatientId => {
          return this.patientSrv.getOne(selectedPatientId)}
      ),
      catchError((err, obs) => {
        console.log(err);
        return obs;
      })
    )
    }catch(err){
      console.error(err)
    }
  }

  loadActivityIcons() : void {
    this.subs.sink = this.getPatientIdentifier()
    .pipe(
      mergeMap(
        ({ patientIdentifier }) => this.patientIconsSrv.getIconsByPatientIdentifier(patientIdentifier)
      ),
      catchError((err, obs) => {
        console.log(err);
        return obs;
      })
    )
    .subscribe(
      (res) => { this.patientIcons$ = of(res) },
    );
    }catch(err){
      console.error(err)
    }


}
