<div class="activity-container" [class.done]="activity?.status == ENACTIVITYSTATUS.done"
  (click)="onActivityClick(activity)">

  <div class=" clm-1" [class.done]="activity?.status == ENACTIVITYSTATUS.done"
    [class.late]="activity?.status == ENACTIVITYSTATUS.late"
    [class.pending]="activity?.status == ENACTIVITYSTATUS.pending"
    [class.todo]="activity?.status == ENACTIVITYSTATUS.todo"
    [class.extra]="activity?.status == ENACTIVITYSTATUS.extra"
    [class.warn]="activity?.status == ENACTIVITYSTATUS.warn">
  </div>

  <div class="clm-2">
    <span>{{ currentDate }}</span>
    <div class="v-divider"></div>
  </div>

  <div class="clm-3">
    <div>
      <span>{{ activity?.activityName }}</span>
      <div class="container-icons">

        <div class="flex" *ngFor="let icon of icons">
          <mat-icon aria-hidden="false" [class.enabled]="true" [svgIcon]="icon">
          </mat-icon>
        </div>

      </div>
    </div>

    <div class="v-divider"></div>

  </div>

  <div class="clm-4">

    <span>{{ activity?.currentActivityDetail?.ActivityStatus?.display || "Status não definido" }}</span>
    <span>DRT: {{ activity?.currentActivityDetail?.Practitioner?.drt || "DRT não" }}</span>
    <span>Obs: {{activity?.currentProgress?.text || "Sem observações" }}</span>

  </div>

  <div class="clm-5">
    <mat-icon aria-hidden="false" svgIcon="handle">
    </mat-icon>
  </div>

</div>
