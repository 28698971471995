import { Injectable } from '@angular/core';
import { EnActivityType, IActivity, IProgress, routeProgress } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirProgressService extends FhirGenericService {

  protected url = routeProgress;

  getActivityByType(activityType: EnActivityType): Observable<IActivity | null> {
    const url = `${this.getUrl()}getActivityByType?type=${activityType}`;
    try {
       const $result = this.http.get<IActivity>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'getActivityByType', error.message);
    }
    return of(null);
  }

  createUnscheduledProgress<T>(data: IProgress | null, medlogicId: number | null): Observable<T | null> {
    const url = `${this.getUrl()}postUnscheduledProgress?medlogicId=${medlogicId}`;
    try {
      const $result = this.http.post<T>(url, { data });
      $result.subscribe( a => console.warn("result do unscheduled post: ", a));
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

}
