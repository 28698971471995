import { EnLoginState, ILogin, ILoginState } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { doLoginAndLoadRoot, successLogin, failLogin, clearLogin } from '../actions/login.actions';

export const loginPWAFeatureKey = 'loginPWA';

export const adapterLogin: EntityAdapter<ILogin> = createEntityAdapter<ILogin>({
  selectId: instance => instance.usuarioLogadoNo,
});

const cleanState = {
  userName: null,
  password: null,
  selectedLogin: null,
  enLoginState: EnLoginState.Login,
  message: null,
  isFirstLogin: null,
}

export const initialStateLogin: ILoginState = adapterLogin.getInitialState({
  // additional entity state properties
  // userName: null,
  // password: null,
  // selectedLogin: null,
  // enLoginState: EnLoginState.Login,
  // message: null,
  // isFirstLogin: null
  ...cleanState
});

const _loginReducer = createReducer(initialStateLogin,
  on(doLoginAndLoadRoot, (state, payload) => {
    return ({
      ...state,
      ...payload
    });
  }),
  on(successLogin, (state, payload) => {
    return ({
      ...state,
      password: '', // Zerar por segurança, para que não permaneça na memória. // FIXME: Há uma forma de nem precisar armazenar a senha no state?
      selectedLogin: payload.selectedLogin
    });
  }),
  on(failLogin, (state, payload) => {
    return ({
      ...state,
      ...payload.selectedLogin,
      password: ''
    });
  }),
  on(clearLogin, (state, payload) => {
    return ({
      // ...state,
      // ...cleanState
      ...initialStateLogin
    });
  }),
  // on(clearLogin,
  //   state => adapterLogin.removeAll({ ...state, selectedLogin: null, message: null })
  // ),
);

export function loginReducer(state, action) {
  return _loginReducer(state, action);
}
