import { selectHasMedicationsByPatient, selectNumOfMedicationsByPatient } from '@medlogic/medlogic/medlogic-state';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';
import { Component, OnInit, Input } from '@angular/core';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { MatDialog } from '@angular/material/dialog';
import { ProcessService } from '@medlogic/shared/shared-data-access';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';
import { GlobalService, LogService, IModule } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Store } from '@ngrx/store';
import { swapImageProcess } from '../../service/pwa-util';
import { clearActivities } from '@medlogic/fhir';

@Component({
  selector: 'ml-patient-action',
  templateUrl: './patient-action.component.html',
  styleUrls: ['./patient-action.component.css']
})
export class PatientActionComponent implements OnInit {

  @Input() patient: IPatient;
  @Input() cadPacienteNo = -1;
  @Input() cadResponsavelFamiliaNo = -1;
  @Input() cadVitalSignsNo = -1;
  @Input() cadCheckMedicamentosNo = -1;
  @Input() cadStockNo = -1;
  @Input() cadEvolutionNo = -1;

  @Input() showVitalSigns = true;
  @Input() showPatientReport = true;
  @Input() showMedicationCheckin = true;
  @Input() showDailyEvaluation = true;
  @Input() showCarePlan = true;
  @Input() showCustomModules = true;

  numOfMedications$: Observable<number>;
  hasMedication$: Observable<boolean>;
  // numMedication: number;
  // hasMedication: boolean;
  usrProcess$: Observable<IModule[]>;

  constructor(
    private cnf: ConfigPwaMedLogicService,
    private glb: GlobalService,
    private nav: NavigationPwaService,
    private log: LogService,
    private matDialog: MatDialog,
    private msg: LocalMsgPtBR,
    private processSrv: ProcessService,
    private store: Store<IAppMedlogicState>
  ) { }

  ngOnInit(): void {
    try {
      this.refresh();
      this.numOfMedications$ = this.store.select(selectNumOfMedicationsByPatient(this.patient?.codigo));
      this.hasMedication$ = this.store.select(selectHasMedicationsByPatient(this.patient?.codigo, this.patient?.calcMedicationsCount));
      // this.store.select(selectNumOfMedicationsByPatient(this.patient?.codigo)).pipe(take(1)).subscribe(res => this.numMedication = res);
      // this.store.select(selectHasMedicationsByPatient(this.patient?.codigo, this.patient?.calcMedicationsCount)).pipe(take(1)).subscribe(res => this.hasMedication = res);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected setCadParams(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  /* Carregamento principal. */
  protected refresh(): void {
    try {
      this.usrProcess$ = this.getUserProcess();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /* Apontamento dos sinais vitais. Deverá direcionar para a tela do GE. */
  vitalSigns(patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoVitalSignList();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'vitalSigns', error.message);
    }
  }

  /* Apontamento da Evolução simplificada. Deverá direcionar para a tela do GE. */
  evolution(patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoEvolutionList();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'evolution', error.message);
    }
  }

  /* Direcionamento para a Timeline do paciente. */
  timelineClick(patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoEvolutionTimeline(this.cadEvolutionNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'timelineClick', error.message);
    }
  }

  /* Apontamento de dados de Emergencia simplificada. Deverá direcionar para a tela do GE. */
  emergencyData(patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoEmergencyData(this.cadResponsavelFamiliaNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'emergencyData', error.message);
    }
  }

  activities(patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoCarePlanActivities(patient.prontuario);
      this.store.dispatch(clearActivities());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'activities', error.message);
    }
  }

  /* Retorna o número total de medicamentos cadastrados para o paciente. */
  // getNumOfMedications(patient: IPatient): number {
  //   try {
  //     // return (patient && patient.medications) ? patient.medications.filter(f => !f.took && f.status !== '-').length : 0;
  //     return (patient?.medications) ?
  //       new MedicationPendingPipe(this.log).transform(patient.medications).length :
  //       patient?.calcMedicationsCount || 0;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getNumOfMedications', error.message);
  //   }
  // }

  /* Apontamento das medicações.  */
  medications(patient: IPatient, numOfMed: number, hasMedication: boolean): void {
    try {
      if (hasMedication) {
        this.cnf.selectedPatient = patient;
        this.nav.gotoCardMedication2(this.cadCheckMedicamentosNo, this.cadStockNo);
      } else {
        const width = '420px';
        const height = '280px';
        this.matDialog.open(UiDialogAlertComponent, {
          width,
          height,
          data: {
            title: 'Atenção',
            width,
            height,
            messageHtml: this.msg.MEDICATION_EMPTY
          }
        });
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'medications', error.message);
    }
  }

  /* Apontamento de uma intercorrência. Deverá direcionar para a tela do GE. */
  intercorrence(patient: IPatient): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'intercorrence', error.message);
    }
  }

  /* Abre a Timeline do paciente. */
  patientTimeline(patient: IPatient): void {
    try {
      // Passagem de parâmetro de dado complexo
      this.cnf.selectedPatient = patient;
      this.nav.gotoTimeline();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'patientTimeline', error.message);
    }
  }

  /* Resgata todos os processos que o usuário tem permissão para acessar. */
  protected getUserProcess(): Observable<IModule[]> {
    try {
      const sort$ = () => map((items: any[]) => items.sort((a, b) => a.OrderNo - b.OrderNo));
      const process$ = this.processSrv.getDataProcess().pipe(toArray(), sort$());
      return process$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUserProcess', error.message);
    }
    return of(null);
  }

  /* Clique em um dos processos, que são carregados dinamicamente. */
  onClickProcess(process: IModule, patient: IPatient): void {
    try {
      this.cnf.selectedPatient = patient;
      this.nav.gotoGenericActivity(process?.ObjInfo?.AtividadeNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickProcess', error.message);
    }
  }

  showImg(process: IModule): boolean {
    try {
      return process && this.glb.isNotNullOrEmpty(process.UrlIcon);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'showImg', error.message);
    }
    return false;
  }

  swapImage(img: HTMLImageElement | EventTarget): void {
    try {
      swapImageProcess(img);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'swapImage', error.message);
    }
  }


}
