import { Injectable } from '@angular/core';
import { routePatientIcons } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { FhirGenericService } from './fhir-generic.service';

@Injectable({ providedIn: 'root' })
export class FhirPatientIconsService extends FhirGenericService {

  protected url = routePatientIcons;

  getIconsByPatientIdentifier(patientIdentifier: string): Observable<any> {
    const url = `${this.getUrl()}getAll?patientIdentifier=${patientIdentifier}`;
    try {
       const $result = this.http.get<any>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'getIconsByPatientIdentifier', error.message);
    }
    return of(null);
  }


}
