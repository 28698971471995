import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalService, LogService,
  LocalLibService,
  ConfigJsonService,
  UnsubscribeOnDestroyAdapter
} from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { FhirCadastroService } from './fhir-cadastro.service';

@Injectable({ providedIn: 'root' })
export abstract class FhirGenericService extends UnsubscribeOnDestroyAdapter {

  protected abstract readonly url: string;

  //TODO: Ajustar o scopo pois o protected está dando erro nos serviços
  constructor(
    public cadastroSrv: FhirCadastroService,
    public glb: GlobalService,
    public cnf: ConfigJsonService,
    public lib: LocalLibService,
    public log: LogService,
    public http: HttpClient
  ) {
    super();
    try { //
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getOne<T>(id: number): Observable<T | null> {
    const url = `${this.getUrl()}getOne?id=${id}`;
    try {
       const $result = this.http.get<T>(url);
       return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  getAll<T>(): Observable<T | null> {
    const url = `${this.getUrl()}getAll`; // TODO: add query IGetFhirActivitiesRequest
    try {
      const $result = this.http.get<T>(url);
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  create<T>(data: T): Observable<T | null> {
    try {
      const url = `${this.getUrl()}post`;
      const $result = this.http.post<T>(url, { data });
      $result.subscribe( a => console.warn("result do create: ", a));
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  createMany<T>(array: T[]): Observable<T[] | null> {
    throw new Error('Method not implemented.');
    // try {
    //    const $result = array.map(a => this.create(a));
    //    return $result;
    // } catch (error: any) {
    //    this.log.Registrar(this.constructor.name, '', error.message);
    // }
    // return [of(null)];
  }

  update<T>(data: T): Observable<T | null> {
    try {
      console.warn("update enviado para a api", { data: data })
      const url = `${this.getUrl()}put`;
      console.warn("para a seguinte url: ", url);
      const $result = this.http.put<T>(url, { data: data });
      return $result;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(null);
  }

  updateMany<T>(array: T[]): Observable<T[] | null> {
    throw new Error('Method not implemented.');
    //   try {
    //     const $result = array.map(a => this.update(a));
    //     return $result;
    //  } catch (error: any) {
    //     this.log.Registrar(this.constructor.name, '', error.message);
    //  }
    //  return of(null);
  }

  delete<T>(id: string): Observable<boolean> {
    try {
      const url = `${this.getUrl()}delete?id=${id}}`;
      const $result = this.http.delete<boolean>(url) as Observable<boolean>;
      return $result;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, '', error.message);
    }
    return of(false);
  }


  protected getUrl(): string {
    if (this.glb.IsNullOrEmpty(this.url)) {
      throw new Error('Url não definida na classe filha');
    }

    return `${this.cnf.baseUrlAPI}${this.url}/`;
  }


}
