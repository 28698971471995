import { IAppMedlogicFhirState, selectedActivityMode } from '@medlogic/fhir';
import { LogService, EnActivityMode } from '@medlogic/shared/shared-interfaces';
import { SvgIconService } from '@medlogic/shared/material';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-header',
  templateUrl: './ui-care-plan-header.component.html',
  styleUrls: ['./ui-care-plan-header.component.css']
})
export class UiCarePlanHeaderComponent implements OnInit {

  @Output() menuItemClick = new EventEmitter<string>();

  activityMode$: Observable<EnActivityMode> = this.store.pipe(select(selectedActivityMode));

  selectedOption: string = EnActivityMode.CarePlan;

  ENACTIVITYMODE = EnActivityMode;

  constructor(
    svgIconSrv: SvgIconService,
    private log: LogService,
    private store: Store<IAppMedlogicFhirState>,
  ) { }

  ngOnInit() {
  }

  onMenuItemClick(option: string): void {
    try {
      this.selectedOption = option;
      this.menuItemClick.emit(option);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onMenuItemClick', error.message);
    }
  }


}
