import { IShift } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IActivity } from '@medlogic/shared/shared-interfaces';


@Component({
  selector: 'ml-ui-care-plan-activities',
  templateUrl: './ui-care-plan-activities.component.html',
  styleUrls: ['./ui-care-plan-activities.component.css']
})
export class UiCarePlanActivitiesComponent implements OnInit {

  @Input() activityModeTitle: string;
  @Input() activitiesByShift: IShift[] = [];

  @Output() activityClick = new EventEmitter<IActivity>();


  constructor(
    private log: LogService
  ) { }

  ngOnInit() {
  }

  onActivityClick(activity: IActivity): void {
    try {
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }


}
