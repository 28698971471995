import { IPractitioner, LogService, UnsubscribeOnDestroyAdapter, EnActivityType, IActivity, IActivityDetail, GlobalService, IProgress, ILogin, EnFieldType, IField, IContributorOnActivity } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { select } from '@ngrx/store';


@Component({
  selector: 'ml-ui-care-plan-actitivy-detail',
  templateUrl: './ui-care-plan-actitivy-detail.component.html',
  styleUrls: ['./ui-care-plan-actitivy-detail.component.css'],
  animations: [
    trigger('panelInOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(800)
      ]),
      transition('* => void', [
        animate(800, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class UiCarePlanActitivyDetailComponent extends UnsubscribeOnDestroyAdapter implements AfterViewInit, OnInit {

  @Output() updateValues = new EventEmitter<{ [fieldName: string]: any }>();
  @Output() confirm = new EventEmitter<{ progress: IProgress, activityType: EnActivityType }>();

  @Input() activity: IActivity = null;
  @Input() progress: IProgress = null;
  @Input() practitioners: MatTableDataSource<IPractitioner & { actions?: string }>;
  @Input() performerPractitioner: ILogin = null;

  today = this.glb.getHoraHHMM(new Date());
  scheduledTime: string;

  _activityType: EnActivityType = EnActivityType.Fralda;

  // No caso de uma nova atividade essa propriedade será preenchida ao passo que a activity será nula.
  @Input() set activityType(value: EnActivityType) {
    this._activityType = value;
  }
  public get activityType(): EnActivityType { return this._activityType || this.activity?.activityType; }

  isNew = () => this.activity === null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // TODO: maybe the fields might be filled on the Page.
  @Input() fields: IField[] | null = [];

  public get filteredFields(): IField[] {
    const fields = this.fields?.filter(field => field.activityType  === this.activityType);
    this.fg = typeof(fields) !== 'undefined' && !this.fg ? this.formGroupFactory(fields, this.performerPractitioner?.usuarioLogadoNo) : this.fg;
    return fields;
  }

  ENACTIVITYTYPE = EnActivityType;
  ENFIELDTYPE = EnFieldType;

  loadedValue: string;

  fg: FormGroup;

  displayedColumns: string[] = ['practitionerIdentifier', 'practitionerName', 'drt'];

  selectedPractitioners$: BehaviorSubject<IPractitioner[]>;


  filterValue: string;
  search = '';
  constructor(
    private log: LogService,
    private glb: GlobalService

  ) {
    super();
  }

  ngOnInit() {

    this.selectedPractitioners$ = new BehaviorSubject<IPractitioner[]>(
      !this.glb.IsArrayNullOrEmpty(this.activity?.currentProgress?.contributorOnActivity) ?
        this.activity.currentProgress.contributorOnActivity :
        []
      );

    const splitted = this.isNew() ? ['',''] : new Date(this.activity?.currentActivityDetail.Scheduled.ScheduledPeriod.start).toUTCString()?.split(" ")[4].split(":");
    this.scheduledTime = `${splitted[0]}:${splitted[1]}`;
    try {
      this.practitioners.filterPredicate = (data: IPractitioner, filter?: string) =>
        (!this.glb.IsNullOrEmpty(filter) && (this.glb.contem(data.practitionerName, filter) || this.glb.contem(data.practitionerIdentifier, filter) || this.glb.contem(data.drt.toString(), filter))) &&
        !this.selectedPractitioners$.value.map(m => m.practitionerName).includes(data.practitionerName);

      this.subs.sink = this.selectedPractitioners$.subscribe(() => {
        this.practitioners.filter = ''; // para disparar o filterPredicate
      });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  public activityDontHaveSelectedPractitioners() {
    return this.glb.IsArrayNullOrEmpty(this.activity?.currentProgress?.contributorOnActivity);
  }

  public loadValue(key: string, fieldName: string): boolean {
    const value = this.activity?.currentProgress?.[fieldName];
    return key === value;
  }

  private formGroupFactory(filteredFields : IField[], usuarioLogadoNo : number) : FormGroup{
    try {
      const fg = new FormGroup(
        filteredFields.reduce((obj, field) => ({
          ...obj,
          [field.fieldName]: new FormControl(this.activity?.currentProgress?.[field.fieldName] ), // change this fieldvalue
        }), {
          // TODO: receive this initial values as parameters.
          practitioners: new FormControl(this.activity?.currentProgress?.contributorOnActivity || []),
          practitionerDrt: new FormControl({ value: usuarioLogadoNo, disabled: true }),
          hadHelp: new FormControl(this.activity?.currentProgress?.hadHelp || false)
        }
        )
      );
      this.subs.sink = fg.valueChanges.subscribe(values => this.updateValues.emit(this.getFieldValues(values, filteredFields)));

      // disables if progress already exists
      if (this.activity?.currentProgress?.progressIdentifier) {
        // for (var control in fg.controls) {
        //   fg.controls[control].disable();
        // }
        fg.controls.practitioners.disable();
        fg.controls.practitionerDrt.disable();
        fg.controls.hadHelp.disable();
        fg.disable();
      }
      return fg;
    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'formGroupFactory', error.message);
    }
     return null;
  }

  ngAfterViewInit() {
    try {
      this.practitioners.paginator = this.paginator;
      this.practitioners.sort = this.sort;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'ngAfterViewInit', error.message);
    }
  }

  /** check conditionalVisibility for the field. */
  isVisible(field: IField): boolean {
    try {
      return field.conditionalVisibility ?
        field.conditionalVisibility.every(conditional =>
          this.glb.isEqual(this.fg?.get(conditional?.fieldName ?? "")?.value, conditional?.value ?? "") ||
          (conditional?.value?.includes && conditional.value.includes(this.fg?.get(conditional?.fieldName ?? "")?.value))
        ) : true;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'isVisible', error.message);
    }
    return true;
  }

  getFieldValues(field: { [key: string]: any }, fields: IField[]): any | null {
    try {
      const contributorOnActivity = this.selectedPractitioners$.value;
      const hadHelp = this.fg.get('hadHelp').value;
      const performerPractitionerIdentifier = this.fg.get('practitionerDrt').value;
      return Object.keys(field)
        .reduce((obj, key) => fields.map(m => m.fieldName).includes(key)
          ? ({ ...obj, [key]: field[key] })
          : obj,
          { hadHelp, performerPractitionerIdentifier, contributorOnActivity });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getFieldValues', error.message);
    }
    return null;
  }

  getKeys(field: { [key: string]: any }): string[] | null {
    try {
      return Object.keys(field);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getKeys', error.message);
    }
    return null;
  }

  getValue(field: { [key: string]: any }, key: string): any | null {
    try {
      return field.options[key];
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'getValue', error.message);
    }
    return null;
  }

  onConfirmClick($event: any, fields: IField[], activity: IActivity, activityType: EnActivityType): void {
    if (this.activity?.currentProgress?.progressIdentifier) return;
    try {
      const progress = this.mapTo(this.getFieldValues(this.fg.value, fields), activity, activityType);
      this.confirm.emit({ progress,
        activityType: activity?.currentActivityDetail?.activityDetailIdentifier ? EnActivityType.Scheduled : activityType, // this checks prepare the emission for the component above to create the rxjs dispatch
      });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onConfirmClick', error.message);
    }
  }

  acceptanceToGUID(code: string) {
    switch (code) {
      case "1":
        return "533BC219-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "543BC219-432D-ED11-AB4B-0AF88D461817";
      case "3":
        return "6303FC21-432D-ED11-AB4B-0AF88D461817";
      case "4":
        return "6403FC21-432D-ED11-AB4B-0AF88D461817";
      case "5":
        return "6503FC21-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  aspectToGUID(code: string) {
    switch (code) {
      case "1":
        return "B6F6D343-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "B7F6D343-432D-ED11-AB4B-0AF88D461817";
      case "3":
        return "85B1664C-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  decubitusPositionToGUID(code: string) {
    switch (code) {
      case "1":
        return "A15CAC5B-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "A25CAC5B-432D-ED11-AB4B-0AF88D461817";
      case "3":
        return "00465D65-432D-ED11-AB4B-0AF88D461817";
      case "4":
        return "01465D65-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  diuresisColorToGUID(code: string) {
    switch (code) {
      case "1":
        return "D8B8257D-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "D9B8257D-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  diuresisQuantityToGUID(code: string) {
    switch (code) {
      case "1":
        return "8DCC6D91-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "8ECC6D91-432D-ED11-AB4B-0AF88D461817";
      case "3":
        return "8FCC6D91-432D-ED11-AB4B-0AF88D461817";
      case "4":
        return "408F8E98-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  evacuationQuantityToGUID(code: string) {
    switch (code) {
      case "1":
        return "6CE064B6-432D-ED11-AB4B-0AF88D461817";
      case "2":
        return "6DE064B6-432D-ED11-AB4B-0AF88D461817";
      case "3":
        return "6EE064B6-432D-ED11-AB4B-0AF88D461817";
      case "4":
        return "33F14DBD-432D-ED11-AB4B-0AF88D461817";
      default:
        return undefined;
    }
  }

  mapTo(formValues: any, activity: IActivity, activityType: EnActivityType): IProgress | null {
    const activityDetail = activity?.currentActivityDetail as IActivityDetail; // TODO: implement this get activityDetailIdentifier
    const progress = activity?.currentProgress as IProgress; // instância atual e particular do progress TODO: implementar
    if (progress?.wasPerformed) return progress;
    try {
      return {
    progressIdentifier: progress?.progressIdentifier || undefined,
    activityDetailIdentifier: activityDetail?.activityDetailIdentifier || undefined,
    performerPractitionerIdentifier: progress?.performerPractitionerIdentifier || formValues.performerPractitionerIdentifier,
    time: new Date(),
    text: progress?.text || undefined,
    activityIdentifier: activity?.activityIdentifier || undefined,
    accept: formValues?.accept || undefined,
    codeAcceptanceIdentifier: this.acceptanceToGUID(formValues?.codeAcceptanceIdentifier) || undefined,
    agitation: formValues?.agitation || undefined,
    codeAspectIdentifier: this.aspectToGUID(formValues?.codeAspectIdentifier) || undefined,
    codeDiuresisColorIdentifier: this.diuresisColorToGUID(formValues?.codeDiuresisColorIdentifier) || undefined,
    codeDiuresisQuantityIdentifier: this.diuresisQuantityToGUID(formValues?.codeDiuresisQuantityIdentifier) || undefined,
    choke: formValues?.choke || undefined,
    codeEvacuationQuantityIdentifier: this.evacuationQuantityToGUID(formValues?.codeEvacuationQuantityIdentifier) || undefined,
    comments: formValues?.comments || undefined,
    codeDecubitusPositionIdentifier: this.decubitusPositionToGUID(formValues?.codeDecubitusPositionIdentifier) || undefined,
    somnolence: formValues?.somnolence || undefined,
    leak: formValues?.leak || undefined,
    contributorOnActivity: !this.glb.IsArrayNullOrEmpty(formValues?.contributorOnActivity) ? formValues?.contributorOnActivity.map(c => ({...c, activityContributorIdentifier: c.practitionerIdentifier})) : undefined,
    externalSupport: formValues?.externalSupport || undefined,
    externalSupportDescription: formValues?.externalSupportDescription || undefined,
    hadHelp: formValues?.hadHelp || undefined,
    fecesLeakness: formValues?.fecesLeakness || undefined,
    urineLeakness: formValues?.urineLeakness || undefined,
    wasPerformed: formValues?.wasPerformed || undefined,
      } as IProgress;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'mapTo', error.message);
    }
    return null;
  }

  onPractitionerClick(row: IPractitioner): void {
    if (this.activity?.currentProgress?.progressIdentifier) return;
    try {
      this.selectedPractitioners$.next([...new Set(this.selectedPractitioners$.value.concat(row))]);
      this.clearFilter();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onPractitionerClick', error.message);
    }
  }


  onSelectedPractitionerClick(row: IPractitioner): void {
    if (this.activity?.currentProgress?.progressIdentifier) return;
    try {
      this.selectedPractitioners$.next([...new Set(this.selectedPractitioners$.value.filter(f => f.practitionerName !== row.practitionerName))]);
      this.clearFilter();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onSelectedPractitionerClick', error.message);
    }
  }

  clearFilter(): void {
    try {
      this.search = '';
      this.filter(this.search);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'clearFilter', error.message);
    }
  }

  applyFilter(event: Event): void {
    try {
      const filterValue = (event.target as HTMLInputElement).value;
      this.filter(filterValue);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'applyFilter', error.message);
    }
  }

  private filter(search: string): void {
    try {
      this.practitioners.filter = search.trim().toLowerCase();

      if (this.practitioners.paginator) {
        this.practitioners.paginator.firstPage();
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'filter', error.message);
    }
  }


}
