import { setShift } from '@medlogic/fhir';
import { AppMedlogicPwaCuidadoState } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { Component, OnInit } from '@angular/core';
import { LogService, EnShift } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ml-ui-care-plan-shift-bar',
  templateUrl: './ui-care-plan-shift-bar.component.html',
  styleUrls: ['./ui-care-plan-shift-bar.component.css']
})
export class UiCarePlanShiftBarComponent implements OnInit {

  ENSHIFT = EnShift;

  constructor(
    private log: LogService,
    private store: Store<AppMedlogicPwaCuidadoState>
  ) { }

  ngOnInit() {
  }

  onClick(shift: EnShift): void {
    try {
      this.store.dispatch(setShift({ shift }));
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onClick', error.message);
    }
  }


}
