<div class="container-footer">

  <div>
    <button *ngIf="showConfirm" mat-stroked-button type="button" (click)="onActionClick(ENACTIONTYPE.cancel)">
      Cancelar
    </button>
  </div>

  <div>
    <button *ngIf="showAdd" type="button" mat-icon-button type="button" title="adicionar"
      (click)="onActionClick(ENACTIONTYPE.add)">
      <mat-icon svgIcon="add-button"></mat-icon>
    </button>
  </div>

  <div>
    <button *ngIf="showConfirm" mat-raised-button type="button" (click)="onActionClick(ENACTIONTYPE.confirm)">
      Confirmar
    </button>
  </div>

</div>