<div class="container-detail">

  <ng-container [ngSwitch]="isNew()">

    <ng-container *ngSwitchCase="true">
      <span>{{ activityType }}</span>
      <span class="time">{{ today }}</span>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>{{ activity.activityName }}</span>
      <span class="time">{{ scheduledTime }}</span>
    </ng-container>

  </ng-container>

  <form [formGroup]="fg" class="example-form" *ngIf="filteredFields?.length > 0">

    <ng-container *ngFor="let field of filteredFields">

      <div *ngIf="isVisible(field)" class="row" [@panelInOut]>
        <ng-container [ngSwitch]="field.fieldType">

          <ng-container *ngSwitchCase="ENFIELDTYPE.SlideToggle">
            <mat-slide-toggle [formControlName]="field.fieldName">{{ field.label }}</mat-slide-toggle>
          </ng-container>

          <ng-container *ngSwitchCase="ENFIELDTYPE.RadioGroup">
            <span>{{ field.label }}</span>
            <mat-radio-group [attr.aria-label]="field.description" [formControlName]="field.fieldName">
              <mat-radio-button *ngFor="let key of getKeys(field.options)" [value]="key" [checked]="loadValue(key, field.fieldName)">{{
                getValue(field, key) }}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>

          <ng-container *ngSwitchCase="ENFIELDTYPE.TextArea">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{ field.label }}</mat-label>
              <textarea matInput [attr.placeholder]="field.description" title="{{ field.label }}"
                [formControlName]="field.fieldName">{{ field.value }}</textarea>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{ field.label }}</mat-label>
              <input matInput [placeholder]="field.description" type="text" title="{{ field.label }}"
                [placeholder]="field.description" [value]="field.value" [formControlName]="field.fieldName">
            </mat-form-field>
          </ng-container>

        </ng-container>
      </div>
    </ng-container>

    <div class="row">

      <div class="clm">
        <mat-form-field class="example-full-width" appearance="fill">

          <mat-label>DRT</mat-label>
          <input matInput (keyup)="filterValue = inputFilter.value" placeholder="DRT" #inputFilter
            formControlName="practitionerDrt">

        </mat-form-field>
      </div>

      <div class="clm">
        <mat-slide-toggle formControlName="hadHelp">Teve Ajuda</mat-slide-toggle>
      </div>

    </div>

    <div class="row" *ngIf="fg.get('hadHelp').value">

      <div class="clm" *ngIf="activityDontHaveSelectedPractitioners()">

        <mat-form-field appearance="standard" class="full-width">
          <mat-label>Pesquisar</mat-label>
          <input matInput (keyup)="applyFilter($event)" (keypress.ESC)="clearFilter()" [(ngModel)]="search" #input
            placeholder="Nome do profissional ou DRT" class="full-width" onfocus="this.select()"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>

        <table mat-table [dataSource]="practitioners" class="mat-elevation-z8">

          <!-- Position Column -->
          <ng-container matColumnDef="practitionerIdentifier">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.practitionerIdentifier}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="practitionerName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.practitionerName}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="drt">
            <th mat-header-cell *matHeaderCellDef> DRT </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button type="button"> {{element.drt}}
                <mat-icon class="add">add</mat-icon>
              </button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="cursor" mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="onPractitionerClick(row)">
          </tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nenhum profissional encontrado {{input.value}}</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Página"></mat-paginator>
      </div>

      <div class="clm mt-2 pl-4">
        <span>Envolvidos na Atividade:</span>

        <table mat-table [dataSource]="selectedPractitioners$ | async" class="mat-elevation-z8 mt-34">

          <ng-container matColumnDef="practitionerIdentifier">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.practitionerIdentifier}} </td>
          </ng-container>

          <ng-container matColumnDef="practitionerName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.practitionerName}} </td>
          </ng-container>

          <ng-container matColumnDef="drt">
            <th mat-header-cell *matHeaderCellDef> DRT </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button type="button"> {{element.drt}}
                <mat-icon class="delete">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="cursor" mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="onSelectedPractitionerClick(row)">
          </tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nenhum colaborador selecionado.</td>
          </tr>
        </table>
      </div>

    </div>

  </form>

</div>

<footer>
  <button type="button" mat-raised-button color="primary"
    (click)="onConfirmClick($event, filteredFields, activity, activityType)">CONFIRMAR</button>
</footer>
