import { ReactiveFormsModule } from '@angular/forms';
import { UiCarePlanTimerComponent } from './ui-care-plan-timer/ui-care-plan-timer.component';
import { UiCarePlanGeneralIndicatorComponent } from './ui-care-plan-general-indicator/ui-care-plan-general-indicator.component';
import { UiCarePlanShiftBarComponent } from './ui-care-plan-shift-bar/ui-care-plan-shift-bar.component';
import { UiCarePlanHeaderComponent } from './ui-care-plan-header/ui-care-plan-header.component';
import { UiCarePlanAvatarComponent } from './ui-care-plan-avatar/ui-care-plan-avatar.component';
import { UiCarePlanActivityComponent } from './ui-care-plan-activity/ui-care-plan-activity.component';
import { UiCarePlanActivitiesComponent } from './ui-care-plan-activities/ui-care-plan-activities.component';
import { MaterialModule } from '@medlogic/shared/material';
import { UiCarePlanRowComponent } from './ui-care-plan-row/ui-care-plan-row.component';
import { UiCarePlanCellComponent } from './ui-care-plan-cell/ui-care-plan-cell.component';
import { UiCarePlanComponent } from './ui-care-plan/ui-care-plan.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCarePlanFooterComponent } from './ui-care-plan-footer/ui-care-plan-footer.component';
import { UiCarePlanPractitionerComponent } from './ui-care-plan-practitioner/ui-care-plan-practitioner.component';
import { UiCarePlanActivityExtraComponent } from './ui-care-plan-activity-extra/ui-care-plan-activity-extra.component';
import { UiCarePlanActivitiesExtraComponent } from './ui-care-plan-activities-extra/ui-care-plan-activities-extra.component';
import { UiCarePlanActitivyDetailComponent } from './ui-care-plan-actitivy-detail/ui-care-plan-actitivy-detail.component';
import { ReactiveComponentModule } from '@ngrx/component';
import { ShiftPipePipe } from './pipe/shift-pipe.pipe';
import { AddActivityDialogComponent } from './add-activity-dialog/add-activity-dialog.component';

const PIPES = [
  ShiftPipePipe
];

const COMPONENTS = [
  UiCarePlanComponent,
  UiCarePlanCellComponent,
  UiCarePlanRowComponent,
  UiCarePlanActivitiesComponent,
  UiCarePlanActivitiesExtraComponent,
  UiCarePlanActivityComponent,
  UiCarePlanActivityExtraComponent,
  UiCarePlanAvatarComponent,
  UiCarePlanFooterComponent,
  UiCarePlanHeaderComponent,
  UiCarePlanShiftBarComponent,
  UiCarePlanGeneralIndicatorComponent,
  UiCarePlanTimerComponent,
  UiCarePlanPractitionerComponent,
  UiCarePlanActitivyDetailComponent
];

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MaterialModule,
  ReactiveComponentModule
];

const DIALOGS = [
  AddActivityDialogComponent
];

@NgModule({
  declarations: [COMPONENTS, PIPES, DIALOGS],
  imports: [MODULES],
  exports: [COMPONENTS],
  entryComponents: [DIALOGS]
})
export class MedlogicUiMedlogicUiCarePlanModule { }
