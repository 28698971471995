import { FhirStateModule } from '@medlogic/fhir';
import { StateUtilsModule } from '@medlogic/shared/state-utils';
import { NgLetModule } from '@ngrx-utils/store';
import { StateQueueModule } from '@medlogic/shared/state-queue';
import { MedlogicStateModule } from '@medlogic/medlogic/medlogic-state';
import { UiDialogConfirmModule } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { SharedSharedJsstoreModule } from '@medlogic/shared/shared-jsstore';
import {
  initConfig,
  ConfigJsonService, SharedInterfacesNgModule
} from '@medlogic/shared/shared-interfaces';
import { MedlogicMedlogicPwaCuidadoFullModule } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { MaterialModule } from '@medlogic/shared/material';

// ANGULAR
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; // Angular5
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
// PROJECT

// PWA
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import localePt from '@angular/common/locales/pt';

import { PwaNavigationViewComponent } from './view/pwa-navigation-view/pwa-navigation-view.component';
import { AuthChildGuardService } from '@medlogic/shared/gecore';
import { StorageModule } from '@ngx-pwa/local-storage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppMedlogicPwaCuidadoRoutingModule } from './app-medlogic-pwa-cuidado-routing.module';
import { MedlogicPwaCuidadoInterceptor } from './service/medlogic-pwa-cuidado-interceptor';

// ******* ATENÇÃO PARA RODAR O PROJETO */
// Ative o plugin do CORS no navegador

registerLocaleData(localePt);

@NgModule({
  declarations: [
    PwaNavigationViewComponent,
  ],
  providers: [
    ConfigJsonService,
    AuthChildGuardService,
    { provide: LOCALE_ID, useValue: 'pt-BR' }, // replace "en-US" with your locale
    { // Para carregar as configurações do arquivo antes que as chamadas sejam realizadas
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigJsonService],
      multi: true
    }
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedInterfacesNgModule,
    HttpClientModule, // substitui HttpModule no angular5
    MedlogicMedlogicPwaCuidadoFullModule,
    SharedSharedJsstoreModule,
    MedlogicPwaCuidadoInterceptor,
    // Todas as rotas carregadas dinâmicamente devem vir antes de AppRoutingModule
    AppMedlogicPwaCuidadoRoutingModule,
    UiDialogConfirmModule,
    StateUtilsModule,
    MedlogicStateModule,
    FhirStateModule,
    StateQueueModule,
    NgLetModule,
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 10,
      name: 'MedLogic App'
    }) : [],
    StorageModule.forRoot({ IDBNoWrap: false }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [PwaNavigationViewComponent]
})
export class AppMedlogicPwaCuidadoModule { }
