import { IPractitioner } from '@medlogic/shared/shared-interfaces';
import { FhirPractitionerService } from './../../service/fhir-practitioner.service';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService
} from '@medlogic/shared/shared-interfaces';
import { withLatestFrom, mergeMap, map, catchError, of } from 'rxjs';
import { loadPractitioners, loadPractitionersSuccess, practitionerFail } from './practitioner.actions';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';

@Injectable()
export class PractitionerEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private practitionerSrv: FhirPractitionerService,
  ) { }


  loadPractitioners$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadPractitioners),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => this.practitionerSrv.getAll<IPractitioner[]>()),
      map((practitioners: IPractitioner[] | null) =>
        practitioners ? loadPractitionersSuccess({ practitioners }) : practitionerFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(practitionerFail({ error: '' }));
      })
    )
  );


}
