// WEBAPI CONTROLLER
export const routeGetFormByName = 'Form/GetFormByName';
export const routeGetFormByNameWithCache = 'Form/GetFormByNameWitchCache';
export const routeGetLabelValueComboBoxByForm = 'Model/GetLabelValueComboBoxByForm';
export const routeGetSubProcessByUser = 'Process/GetSubProcessByUser';
export const routeGetBySchool = 'Person/GetBySchool';
export const routeGetAllAuthorized = 'Person/GetAllAuthorized';
export const routeGetForm = 'Form/GetForm';
export const routeGetDataRegister = 'Form/GetDataRegister';
// MEDLOGIC
export const routeGetPatientsWithMedications = 'Form/GetPatientsWithMedications';
export const routeGetAuthorizedPatients = 'Form/GetAuthorizedPatients';
export const routeGetMedLogic = "api/MedLogic";
export const routeGetStyle = "Style/GetStyle";
export const routeGetWSServer = "api/WSServer";
export const routeGetIvcf = "Ivcf";
export const routeGetRawMedication = "Medication/Raw";
export const routeGetHistoryMedication = "Medication/History";
export const routeGetTherapeuticByNames = "Medication/TherapeuticByNames";
export const routeGetAuthorizedInterventions = "Intervention/Authorized";
export const routeGetMedicationCountForPatients = "Medication/GetCountForPatients";
export const routePostCheckMedicationStock = 'Prescription/PostCheckMedicationStock';
export const routeGetWebsocketRoomMessage = 'Prescription/GetWebsocketRoomMessage';
export const routePostManyCheckins = 'Medication/PostManyCheckins';


// FILEUPLOAD CONTROLLER
export const routePostAttachment = 'File/PostAttachment';
export const routeGetAttachmentHeaders = 'File/GetAttachmentHeaders';
export const routeDeleteAttachment = 'File/DeleteAttachment';

// NetcoreApiController
export const routeAuthWebApiBase = 'ApplicationUser';
export const routeUserExist = `${routeAuthWebApiBase}/UserExist`;
export const routeRegister = `${routeAuthWebApiBase}/Register`;
export const routeLogin = `${routeAuthWebApiBase}/Login`;
export const routeUserRoles = `${routeAuthWebApiBase}/UserRoles`;
export const routeEnableUser = `${routeAuthWebApiBase}/EnableUser`;

// PythonAPIController
export const routeSendEmail = 'Email/Send';
export const routeGenerateToken = 'Token/Generate';
export const routeValidateToken = 'Token/Validate';
export const routeFileUpload = 'File/HttpFileUpload';

// Webservice
export const routePostWebService = 'PostWebService';

// Domain Controllers
export const routeGetPostsWithRelationships = 'Post/GetPostsWithRelationships';

// Redis
export const routeRedisGetKeys = 'Redis/GetKeys';
export const routeRedisGetHashes = 'Redis/GetHashes';
export const routeRedisDeleteKeys = 'Redis/DeleteKeys';
export const routeRedisDeleteTenantKeys = 'Redis/DeleteTenantKeys';
export const routeDeleteAllCaches = 'Redis/DeleteAllCaches';
export const routeDeleteRedisInvalidateCache = 'Redis/DeleteInvalidateCache';
export const routeDeleteRedisAllByTenantId = 'Redis/DeleteRedisAllByTenantId';
export const routeDeleteRedisAllByCustomerId = 'Redis/DeleteRedisAllByCustomerId';
export const routeDeleteRedisAllByUserId = 'Redis/DeleteRedisAllByUserId';
export const routeRedisPostLog = 'Redis/PostLog';
export const routeRedisPostLogPrescription = 'Redis/PostLogPrescription';

// internal routes
export const routeInternalMedlogicevolutionGetHasOccurrenceFromCache = 'medlogicevolution/getHasOccurrenceFromCache$';
export const routeInternalIntervencoesMedicamentosServiceHorarioGetAllFromCache = 'intervencoesmedicamentosservicehorario/getAllFromCache$';
export const routeInternalInterventionMedicationGetAllFromCache = 'interventionmedication/getAllFromCache$';
export const routeInternalMedicationCheckinGetAllFromCache = 'medicationcheckin/getAllFromCache';
export const routeInternalPatientGetAllFromCache = 'patient/getAllFromCache$';
export const routeInternalVitalSignGetHasOccurrenceFromCache = 'vitalsign/getHasOccurrenceFromCache$';
export const routeInternalIvcfGetAllFromCache = 'ivcf/getAllFromCache$';

// Assets
export const assetRawMedication = 'assets/data/raw-medication.json';
export const assetTherapeuticSafe = 'assets/data/terapeutica-segura.json';

// Fhir
export const routeGetCarePlanFields = 'GetCarePlanFields';

export const routeFields = 'Fields';
export const routeGetFields = `${routeFields}/getAll`
//Fhir Patient Data
export const routePatientData = 'PatientData';
export const routeGetPatientData = `${routePatientData}/getAll`;

//ProductCodeableActivityIcons
export const routePatientIcons = 'PatientIcons';
export const routeGetAllPatientIcons = `${routePatientIcons}/getAll`;


// Activity
//TODO: PARA TODOS OS MÓDULOS
// TODO: criar a url, padronizada com primeira maiuscula e pascal case.
// TODO: criar a url base do módulo e utilizar ela nas demais rotas.
export const routeActivity = 'Activity';
export const routeGetAllActivities = `${routeActivity}/getAll`;
export const routeGetActivityById = `${routeActivity}/getOne`;
export const routePostActivity = `${routeActivity}/post`;
export const routeGetCurrentActivity = `${routeActivity}/getCurrentActivity`;

// ActivityDetail
export const routeActivityDetails = 'ActivityDetails';
export const routeGetAllActivityDetails = `${routeActivityDetails}/getAll` ; //'ActivityDetails/getAll'
export const routeGetActivityDetailsById = `${routeActivityDetails}/getOne`; //'ActivityDetails/getOne'
export const routePostActivityDetail = `${routeActivityDetails}/post`; //'ActivityDetail/post'
export const routeDeleteActivityDetailById = `${routeActivityDetails}/deleteOne`;

// ActivityStatus
export const routActivityStatus = 'ActivityStatus';
export const routGetAllActivityStatus = `${routActivityStatus}/getAll`;
export const routGetActivityStatusById = `${routActivityStatus}/getOne`;
export const routePostACtivityStatus = `${routActivityStatus}/post`;

// ActivityType
export const routeActivityType = 'ActivityType';
export const routeGetAllActivityType = `${routeActivityType}/getAll`;

// AcrivityDetailKind
export const routeActivityDetailKind = 'ActivityDetailKind';
export const routeGetAllActivityDetailKind = `${routeActivityDetailKind}/getAll` ; //'ActivityDetails/getAll'
export const routeGetActivityDetailKindById = `${routeActivityDetailKind}/getOne`; //'ActivityDetails/getOne'
export const routePostActivityDetailKind = `${routeActivityDetailKind}/post`; //'ActivityDetail/post'

//Address
export const routeAddress = 'Address';

//CarePlanActivity
export const routeCarePlanActivity = 'CarePlanActivity';

// CarePlanCategory
export const routeCarePlanCategory = 'CarePlanCategory';

// CarePlanIntent
export const routeCarePlanIntent = 'CarePlanIntent';

// CarePlan
export const routeCarePlan = 'CarePlan';

// Coding
export const routCoding = 'Coding';

// ContactPoint
export const routeContactPoint = 'ContactPoint';

// DayOfWeek
export const routeDayOfWeek = 'DayOfWeek';

// EventTime
export const routeEventTime = 'EventTime';

// Frequency
export const routeFrequency = 'Frequency';

// Frequency
export const routeHumanName = 'HumanName';

// Identifier
export const routeIdentifier = 'Identifier';

// Patient
export const routePatient = 'Patient';
export const routeGetAllPatient = `${routePatient}/getAll`
export const routeGetPatientById = `${routePatient}/getOne`;

//Practitioner
export const routePractitioner = 'Practitioner';
export const routeGetAllPractitioner = `${routePractitioner}/getAll`;

// Progress
export const routeProgress = 'Progress';
export const routeGetAllProgresses = `${routeProgress}/getAll`;
export const routeGetProgressById = `${routeProgress}/getOne`;
export const routeUpdateProgress = `${routeProgress}/put`;
export const routePostProgress = `${routeProgress}/post`;
export const routeGetActivityByType = `${routeProgress}/getActivityByType`;
export const routePostUnscheduledProgress = `${routeProgress}/postUnscheduledProgress`;


// Qualification
export const routeQualification = 'Qualification';

// Reference
export const routeReference = 'Reference';

// ScheduledPeriod
export const routeScheduledPeriod = 'ScheduledPeriod';

// ScheduledTimingRepeat
export const routeScheduledTimingRepeat = 'ScheduledTimingRepeat';

// ScheduledTiming
export const routeScheduledTiming = 'ScheduledTiming';

// Scheduled
export const routeScheduled = 'Scheduled';

// Icons
export const routeActivityIcons = 'ActivityIcons';
export const routeGetAllActivityIcons = `${routeActivityIcons}/getAll` ;


