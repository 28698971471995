import { setTenantToken, loadTenantGoRoot, setIsLoading } from '@medlogic/medlogic/medlogic-state';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError, tap, switchMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { LoginActionTypes, successLogin, failLogin, clearLogin } from '../actions/login.actions';
import { ILoginState } from '../states/ilogin-state';
import { Observable } from 'rxjs';
import { AppMedlogicPwaCuidadoState } from '../states/app-state';
import { Store } from '@ngrx/store';
import { ILogin, ILoginWithUserResponse, ITenantState } from '@medlogic/shared/shared-interfaces';
import { error } from '../../service/error';
import { GlobalService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../../pwa/service/config-pwa-medlogic.custom.service';
import { LocalLoginService } from '../../service/local-login.service';
import { CadConfigService } from '@medlogic/shared/gecore';
import { LocalMsgPtBR } from '../../service/local-msg-ptBR.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarNotificationService } from '@medlogic/shared/ui/ui-snackbar-notification';

@Injectable()
export class LoginEffects {

  /* Faz o login, baseado em usuário em senha.
  * O token do config será gravado durante a chamada de getLogin.
  * Retornará true se login positivo.
  */
  login$ = () => mergeMap((state: ILoginState): Observable<ILogin | { message: string }> => {
    this.store.dispatch(clearLogin());
    if (!this.glb.isNullOrEmpty(state?.userName) && !this.glb.isNullOrEmpty(state?.password)) {
      // Clean cached data, because new credentials will be set.
      this.cnf.reset();
      // const getImgUrl$ = () => mergeMap((objLogin: ILoginWithUserResponse | any): Observable<ILogin> => {
      //   return (!objLogin?.msg && objLogin?.token) ? this.styleSrv.getLogoUrl(objLogin.session).pipe(map(imgUrl => ({ ...objLogin, imgUrl } as ILoginWithUserResponse))) : of(objLogin);
      // });
      const isValid = () => mergeMap((objLogin: ILoginWithUserResponse | any) => {
        if (objLogin.message === 'Unauthorized') {
          this.snackNotification.open('Usuário ou senha inválidos.', '', 3);
        } else if (!objLogin?.msg && objLogin?.token) {
          return of({
            token: objLogin?.token,
            usuarioLogadoNo: objLogin?.usuarioLogadoNo,
            usuarioLogadoNome: state?.userName,
            tenantId: objLogin?.tenantId,
            imgUrl: objLogin?.imgUrl,
            customerId: objLogin?.customerId
          } as ILogin);
        }
        this.store.dispatch(setIsLoading({ isLoading: false }));
        // return of({ message: objLogin?.msg });
        return EMPTY;
      });
      return this.loginSrv.getLoginWithUser(state?.userName, state?.password)
        .pipe(
          isValid(),
          error()
        );
    }
    return of({ message: 'Login fail!' });
  });

  // private setTenatToken$ = () => mergeMap((login: ILogin): Observable<[TypedAction<TenantActionTypes.SetTenantToken>, ILogin]> => of([setTenantToken({ token: login?.token }), login]));
  private setTenatToken$ = () => mergeMap((login: ILogin) => {
    this.store.dispatch(setTenantToken({ token: login?.token, login }));
    return of(login);
  })

  private getTenantId$ = () => mergeMap((login: ILogin): Observable<ILogin> => {
    if (login?.message) {
      return of(login);
    }
    return this.cad.getCadTenantConfig()  // getCadIdosoBemCuidado para acesso direto
      .pipe(
        map(atividade => {
          const tenantId = atividade?.AtividadeNo || -1;
          this.cnf.tenantId = tenantId;
          return (
            {
              ...login,
              tenantId
            } as ILogin);
        })
      );
  });

  /* It's necessary because some lib services, such as ProcessService, try
  * to read from cnf properties.
  */
  private fillCnf = () => tap((login: ILogin | { message: string }) => {
    if (!login.message) {
      this.cnf.baseUsuarioToken = (login as ILogin)?.token;
      this.cnf.usuarioLogadoNo = (login as ILogin)?.usuarioLogadoNo;
      this.cnf.usuarioLogadoNome = (login as ILogin)?.usuarioLogadoNome;
      this.cnf.tenantId = (login as ILogin)?.tenantId;
      this.cnf.customerId = (login as ILogin)?.customerId;
    }
  })

  private dispatchTenant = () => tap((login: ILogin | { message: string }) => {
    if (!login.message) {
      this.store.dispatch(loadTenantGoRoot({
        tenantId: (login as ILogin)?.tenantId,
        loggedUserName: (login as ILogin)?.usuarioLogadoNome,
        enTheme: EnTheme.black,
        token: (login as ILogin)?.token,
        imgUrl: (login as ILogin)?.imgUrl,
        customerId: (login as ILogin)?.customerId
      } as ITenantState));
    }
  })

  private showFailMessage$ = () => tap((selectedLogin: ILogin | { message: string }) => {
    if (selectedLogin?.message) {
      this.snackBar.open(this.msg.LOGIN_FAIL, '', {
        duration: 2000,
      });
    }
  })

  private failOrSuccess$ = () => switchMap((selectedLogin: ILogin | { message: string }) => {
    if (!selectedLogin?.message) {
      return [successLogin({ selectedLogin })];
    }
    return [failLogin({ selectedLogin }), setIsLoading({ isLoading: false })];
  });

  /* O direcionamento para root ocorrerá em tenant.effect */
  doLoginAndLoadRoot = createEffect(() => this.actions$
    .pipe(
      ofType(LoginActionTypes.DoLoginAndLoadRoot),
      this.login$(),
      this.setTenatToken$(),
      this.getTenantId$(),
      this.fillCnf(),
      this.dispatchTenant(),
      this.showFailMessage$(),
      this.failOrSuccess$(),
      catchError((e) => {
        console.log(e);
        return of(failLogin({ selectedLogin: { message: 'Login fail!' } }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private loginSrv: LocalLoginService,
    private glb: GlobalService,
    private cnf: ConfigPwaMedLogicService,
    private store: Store<AppMedlogicPwaCuidadoState>,
    protected cad: CadConfigService,
    private msg: LocalMsgPtBR,
    private snackBar: MatSnackBar,
    private snackNotification: SnackbarNotificationService
  ) { }


}
