import { FhirProgressService } from './../../service/fhir-progress.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { GlobalService, ILogin, IProgress } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { withLatestFrom, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { loadProgresses, loadProgressesSuccess, loadProgressSuccess, progressFail, upsertProgress, upsertUnscheduledProgress } from './progress.actions';


@Injectable()
export class ProgressEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions, private store: Store<IAppMedlogicState>,
    private progressSrv: FhirProgressService,
  ) { }

  loadProgresses$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadProgresses),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => this.progressSrv.getAll<IProgress[]>()),
      map((progresses: IProgress[] | null) =>
        progresses ? loadProgressesSuccess({ progresses }) : progressFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(progressFail({ error: '' }));
      })
    )
  );

  upsertProgress$ = createEffect(() => this.actions$
  .pipe(
    ofType(upsertProgress),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => action.progress.progressIdentifier ?
      this.progressSrv.update<IProgress>(action.progress) :
      this.progressSrv.create<IProgress>({...action.progress, loginName: (state.loginPWA.selectedLogin as ILogin)?.usuarioLogadoNome} as IProgress)
     ),
    map((progress: IProgress | null) => {
      return progress ? loadProgressSuccess({ progress }) : progressFail({ error: '' })// chamei o método do loadActivity aqui, talvez precisará do setIsLoading como a função acima?
    }),
    catchError((e: any) => {
      console.log(e);
      return of(progressFail({error: ''}));
    })
  ),
)

 upsertUnscheduledProgress$ = createEffect(() => this.actions$
   .pipe(
     ofType(upsertUnscheduledProgress),
     withLatestFrom(this.store),
     mergeMap(([action, state]) => this.progressSrv.getActivityByType(action.activityType).pipe(
      mergeMap((activity) => this.progressSrv.createUnscheduledProgress<IProgress>({ ...action.progress, activityIdentifier: activity?.activityIdentifier as string }, state.patient.selectedId)),
     )),
     map((progress: IProgress | null) => {
       return progress ? loadProgressSuccess({ progress }) : progressFail({ error: '' })
     }),
     catchError((e: any) => {
       console.log(e);
       return of(progressFail({error: ''}));
     })
   ),
 )


}
