import { Injectable } from '@angular/core';
// TODO: remover as importações não utilizadas
import { FhirGenericService } from './fhir-generic.service';
import { routePractitioner } from '@medlogic/shared/shared-interfaces';

@Injectable({ providedIn: 'root' })
export class FhirPractitionerService extends FhirGenericService { // TODO: extends de FhirGenericService

  // TODO: pegar a rota base da API
  protected url = routePractitioner;


}
