<div class="container-indicator">

    <span>Dados Gerais</span>

    <div class="container-icons">

        <div class="flex" *ngFor="let icon of patientsIcons">

            <mat-icon aria-hidden="false" [class.enabled]="true" [svgIcon]="icon.display" (click)="alertaIcone(icon.description)">
            </mat-icon>

            <div class="v-divider"></div>

        </div>

    </div>

    <div class="divider"></div>


</div>

