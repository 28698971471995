import { IActivity, LogService, EnActivityStatus, IActivityIcon, GlobalService } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-activity',
  templateUrl: './ui-care-plan-activity.component.html',
  styleUrls: ['./ui-care-plan-activity.component.css']
})
export class UiCarePlanActivityComponent implements OnInit {


  @Output() activityClick = new EventEmitter<IActivity>();

  @Input() activity: IActivity;

  @Input() activityIcons: IActivityIcon[] | null = [];

  icons : string[] = [];
  currentDate: string;


  ENACTIVITYSTATUS = EnActivityStatus;

  constructor(
    private log: LogService,
    private glb: GlobalService,

  ) {}

  ngOnInit() {
    this.loadIcons();
    const splitted = new Date(this.activity?.currentActivityDetail.Scheduled.ScheduledPeriod.start).toUTCString().split(" ")[4].split(":");
    this.currentDate = `${splitted[0]}:${splitted[1]}`;
  }



  onActivityClick(activity: IActivity): void {
    try {
      console.log("atividade clicada: ");
      console.warn(activity);
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  loadIcons(): void{
    let i = 0;
    this.activity?.icons.forEach(icon => this.icons[i++] = icon.toString());
  }


}
