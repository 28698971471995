import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { EnActivityStatus, GlobalService, IActivity, IActivityTypeCodeableConcept, IFhirPatient } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { withLatestFrom, mergeMap, catchError, of, map } from 'rxjs';
import { loadActivities, loadActivitiesSuccess, activityFail, loadActivityTypes, loadActivityTypesSuccess, activityTypesFail } from './activity.actions';
import { FhirActivityService } from '../../service/fhir-activity.service';
import { FhirActivityTypeService } from '../../service/fhir-activity-type.service';
import { setScheduled } from '../../state-scheduled/+state/scheduled.actions';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ActivityEffects {

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private activitySrv: FhirActivityService,
    private activityTypeSrv: FhirActivityTypeService,
    private http: HttpClient,
  ) { }




  loadActivities$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadActivities),
      withLatestFrom(this.store), // TODO
      mergeMap(([action, state]) => {
        return this.activitySrv.getActivitiesByPatientId<IActivity[]>(state.patient.selectedId)
      }),
      map((activities: IActivity[] | null) => this.generateStatus(activities as IActivity[])),
      map((activities: IActivity[] | null) =>
        activities ? loadActivitiesSuccess({ activities }) : activityFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityFail({ error: '' }));
      }),
    )
  );

  loadActivityTypes$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadActivityTypes),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => this.activityTypeSrv.getAll<IActivityTypeCodeableConcept[]>()),
      map((activityTypes: IActivityTypeCodeableConcept[] | null) =>
        activityTypes ? loadActivityTypesSuccess({ activityTypes }) : activityTypesFail({ error: '' })
        // setIsLoading({ isLoading: false })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(activityTypesFail({ error: '' }));
      })
    )
  );

  generateStatus = (activities: IActivity[]): IActivity[] | null => {
    return activities.map((a: IActivity) => {
      const scheduledTime = a.currentActivityDetail?.Scheduled ? new Date(a.currentActivityDetail?.Scheduled.ScheduledPeriod.start as string) : null;
      const concludedTime = a.currentProgress?.time ? new Date(a.currentProgress?.time) : null;
      const now = new Date();

      // const date = new Date().toLocaleTimeString('pt-BR',{
      //   hour: "2-digit",                              //Exibirá horas.
      //   minute: "2-digit",                            //Exibirá minutos.
      //   second: "2-digit"                             //Exibirá segundos.
      // })
      // const activityScheduledTime = shceduledTime.toLocaleTimeString('pt-BR')
      // const progresCheckinTime = progressCheckin?.toLocaleTimeString('pt-BR')
      const fusoHorario = +3;

      const scheduledHour = scheduledTime?.getHours();
      const scheduledMin = scheduledTime?.getMinutes();
      const scheduledTotal = ((fusoHorario + Number(scheduledHour)) * 60) + Number(scheduledMin);

      const concludedHour = concludedTime?.getHours();
      const concludedMin = concludedTime?.getMinutes();
      const concludedTotal = ((fusoHorario + Number(concludedHour)) * 60) + Number(concludedMin);

      const nowHour = now.getHours();
      const nowMin = now.getMinutes();
      const nowTotal = (Number(nowHour) * 60) + Number(nowMin);

      if (!scheduledTime) return {...a, status: EnActivityStatus.extra }//checks if is an planned activity or not

      if (concludedTime) {
        if((concludedTotal <= scheduledTotal + 30) && (concludedTotal >= scheduledTotal - 30)) {
          return {...a, status: EnActivityStatus.done };
        }
        return {...a, status: EnActivityStatus.late }
      }

      if ((nowTotal <= scheduledTotal + 30) && (nowTotal >= scheduledTotal - 30)) {
        return { ...a, status: EnActivityStatus.warn }
      }

      if (nowTotal <= scheduledTotal) return { ...a, status: EnActivityStatus.todo }
      if (nowTotal > scheduledTotal + 30) return { ...a, status: EnActivityStatus.pending }

      return {...a, status: EnActivityStatus.extra }
    });
  }
}

/*
- .done lançada dentro da tolerância
- .todo Azul, dentro da tolerancia, nao lancada
- .warn tolerancia 30 min antes e 30 min depois do programado
.pending vermelho, pendente, após a tolerancia
- .late amarelo, lançado, mas fora da toletrancia
- .extra cinza: tarefas não programadas




*/
