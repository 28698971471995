import { IActivityTypeCodeableConcept } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseNavigationService } from '@medlogic/shared/shared-data-access';
import { Location } from '@angular/common';

@Injectable()
export class NavigationPwaService extends BaseNavigationService {

  private history: string[] = []

  constructor(router: Router, private location: Location) {
    super(router);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    });
  }

  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  /* Vai para a página inicial da aplicação. */
  gotoRoot(cadConfigNo: number, forceRefresh: boolean = false): void {
    this.cadRootConfigNo = cadConfigNo;
    if (forceRefresh) {
      const rnd = Math.round(Math.random() * 100);
      this.router.navigate(this.getRouteRoot(cadConfigNo), { queryParams: { refresh: rnd } });
      // Adiciona um parâmetro qualquer para forçar refresh.
    } else {
      this.router.navigate(this.getRouteRoot(cadConfigNo));
    }
  }

  /*Retorna a rota inicial da medicação, que é a página com os pacientes.
      * Para direcionamento, utilizar gotoRoot.
      */
  getRouteRoot(cadConfigNo: number): any[] {
    return this.getRouteMedicationHome(cadConfigNo);
  }

  /*Retorna a rota inicial da medicação, que é a página com os pacientes.
    * Para direcionamento, utilizar gotoMedicationHome.
    */
  getRouteMedicationHome(cadConfigNo: number): any[] {
    return [`pwa/carecard/${cadConfigNo}`];
  }

  gotoMedicationHome(cadConfigNo: number): void {
    this.router.navigate(this.getRouteMedicationHome(cadConfigNo));
  }

  getRouteEvolution(evolutionNo: number): any[] {
    return [`pwa/evolution/${evolutionNo}`];
  }

  gotoCarePlanActivities(patientId: string): void {
    this.router.navigate(this.getCarePlanActivities(patientId));
  }

  getCarePlanActivities(patientId: string): any[] {
    return [`pwa/careplan/${patientId}`];
  }

  gotoActivityCreate(): void {
    this.router.navigate(this.getActivityCreate());
  }

  getActivityCreate(): any[] {
    return [`pwa/careplan/create`];
  }

  gotoActivityDetail(activityIdentifier: string): void {
    this.router.navigate(this.getActivityDetail(activityIdentifier));
  }

  getActivityDetail(activityIdentifier: string): any[] {
    return [`pwa/careplan/detail/${activityIdentifier}`];
  }

  gotoEvolution(evolutionNo: number): void {
    this.router.navigate(this.getRouteEvolution(evolutionNo));
  }

  getRouteGenericActivity(genericActivityNo: number): any[] {
    return [`pwa/activity/${genericActivityNo}`];
  }

  gotoGenericActivity(genericActivityNo: number): void {
    this.router.navigate(this.getRouteGenericActivity(genericActivityNo));
  }

  getRouteEvolutionTimeline(evolutionNo: number): any[] {
    return [`pwa/evolution-timeline/${evolutionNo}`];
  }

  gotoEvolutionTimeline(evolutionNo: number): void {
    this.router.navigate(this.getRouteEvolutionTimeline(evolutionNo));
  }

  getRouteKitchen(modeloConfigNo: number): any[] {
    return [`pwa/kitchen/inventory/${modeloConfigNo}`];
  }

  gotoKitchen(modeloConfigNo: number): void {
    this.router.navigate(this.getRouteKitchen(modeloConfigNo));
  }

  getRouteEmergencyData(ResFamilyNo: number): any[] {
    return [`pwa/emergencydata/${ResFamilyNo}`];
  }

  gotoEmergencyData(ResFamilyNo: number): void {
    this.router.navigate(this.getRouteEmergencyData(ResFamilyNo));
  }

  getRouteCardPatient(modeloConfigNo: number): any[] {
    return [`pwa/carecard/${modeloConfigNo}`];
  }

  gotoCardPatient(modeloConfigNo: number = null): void {
    this.router.navigate(this.getRouteCardPatient(modeloConfigNo));
  }

  getRouteLogin(): any[] {
    return [`pwa/login`];
  }

  // EDIT/READD
  gotoLogin(): void {
    this.router.navigate(this.getRouteLogin());
  }

  // PWA
  // CADASTRO Sinais Vitais - Single Page
  getRouteVitalSign(vitalsignNo: number): any[] {
    return [`pwa/vitalsign/${vitalsignNo}`];
  }

  getRouteVitalSignList(): any[] {
    return [`pwa/vitalsign/list`];
  }

  getRouteEvolutionList(): any[] {
    return [`pwa/evolution/list`];
  }

  gotoVitalSign(vitalsignNo: number): void {
    this.router.navigate(this.getRouteVitalSign(vitalsignNo));
  }

  gotoRouteVitalSignList(): void {
    this.router.navigate(this.getRouteVitalSignList());
  }

  gotoRouteEvolutionList(): void {
    this.router.navigate(this.getRouteEvolutionList());
  }

  getRouteCardMedication2(modeloConfigNo: number, cadStockNo: number): any[] {
    return [`pwa/medication/${modeloConfigNo}/${cadStockNo}`];
  }

  gotoCardMedication2(modeloConfigNo: number, cadStockNo: number): void {
    this.router.navigate(this.getRouteCardMedication2(modeloConfigNo, cadStockNo));
  }

  getRouteLongitudinalHistory(vitalsignNo: number, patientCod: number, patientName: string, YYYYMMdd: string): any[] {
    return [`pwa/history/${vitalsignNo}/${patientCod}/${patientName}/${YYYYMMdd}`];
  }

  gotoLongitudinalHistory(vitalsignNo: number, patientCod: number, patientName: string, YYYYMMdd: string): void {
    this.router.navigate(this.getRouteLongitudinalHistory(vitalsignNo, patientCod, patientName, YYYYMMdd));
  }

  getRouteCareMap(modeloConfigNo: number = null): any[] {
    if (modeloConfigNo) {
      return [`pwa/caretable/${modeloConfigNo}`];
    } else {
      return [`pwa/caretable`];
    }
  }

  gotoCareMap(modeloConfigNo: number = null): void {
    this.router.navigate(this.getRouteCareMap(modeloConfigNo));
  }

  gotoTimeline(): void {
    this.router.navigate(this.getRouteTimeline());
  }
  getRouteTimeline(): any[] {
    return [`/rpt/Timeline`];
  }

  gotoVitalSignList(): void {
    this.router.navigate(this.getRouteVitalSignList());
  }

  gotoEvolutionList(): void {
    this.router.navigate(this.getRouteEvolutionList());
  }


}
