import { EnActivityMode, GlobalService, IActivity, EnActivityStatus, IShift, EnShift } from '@medlogic/shared/shared-interfaces';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IActivityState } from './activity-state';

import * as fromActivity from './activity.reducer';

const glb = new GlobalService();

export const selectActivityState = createFeatureSelector<IActivityState>(fromActivity.activityFeatureKey); //TODO: antes estava activitiesFeatureKey (Validar)
export const selectActivityAll = createSelector(selectActivityState, fromActivity.selectAll);

export const selectActivitiesByShift = createSelector(
  selectActivityAll,
  activities => Object.keys(
    activities?.reduce((acc, activity) => {
      // reduce activities by shift
      const shiftName = glb.getShift(glb.dateTohhmmss(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start, false));
      if (!acc[shiftName]) {
        acc[shiftName] = [];
      }
      acc[shiftName].push(activity);
      return acc;
    },
      {} as { [key: string]: IActivity[] }
    )
  ).reduce((acc, shiftName) => {
    const activitiesOfShiftOrderedByTime = activities
      .filter(activity => glb.getShift(glb.dateTohhmmss(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start, false)) === shiftName)
      .sort((a, b) => glb.compareDates(glb.getTypedValue(a?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).value as Date, glb.getTypedValue(b?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start).value as Date));

    acc.push({
      shiftName,
      activities: activitiesOfShiftOrderedByTime
    });

    return acc;

  },
    [] as IShift[]
  )
);

export const selectActivitiesPlannedByShift = createSelector(
  selectActivitiesByShift,
  activities => activities.map(activity => ({ ...activity, activities: activity.activities.filter(a => a.status !== EnActivityStatus.extra) }))
);

export const selectActivitiesExtraByShift = createSelector(
  selectActivitiesByShift,
  activities => activities.map(activity => ({ ...activity, activities: activity.activities.filter(a => a.status === EnActivityStatus.extra) }))
);

export const selectedActivity = createSelector(selectActivityState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const selectedActivityMode = createSelector(selectActivityState, state => {
  return state?.selectedActivityMode;
});

export const selectedActivityType = createSelector(selectActivityState, state => {
  return state?.selectedActivityType;
});

export const selectedActivityModeTitle = createSelector(selectActivityState, state => {
  switch (state?.selectedActivityMode) {
    case EnActivityMode.Extra:
      return 'Atividades Não Programadas';
    default:
    case EnActivityMode.CarePlan:
      return 'Atividades Programadas';
  }
});

export const isActivityLoading = createSelector(
  selectActivityAll,
  activities => activities?.length <= 0,
);

export const selectedActivityIdentifier = createSelector(selectActivityState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});

export const selectedShift = createSelector(selectActivityState, state => {
  return (state?.selectedShift || EnShift.all);
});

export const selectActivitityTypes = createSelector(selectActivityState, state => {
  return state.activityTypes;
});
