<div class="container">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Escolha o Tipo de Atividade:</mat-label>
      <mat-select [(ngModel)]="activityType" (selectionChange)="onActivityTypeChange(activityType)">
        <mat-option *ngFor="let activityType of activityTypes" [value]="activityType">
          {{activityType.type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-button (click)="onActionClick(ENACTIONTYPE.cancel)">Cancelar</button>
    <button type="button" mat-button (click)="onActionClick(ENACTIONTYPE.confirm, activityType)">Confirmar</button>
  </mat-dialog-actions>
</div>