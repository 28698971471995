<div class="activities-container" *ngIf="activitiesByShift?.length > 0; else empty">

    <span>{{ activityModeTitle }}</span>

    <div *ngFor="let shift of activitiesByShift" class="shift">

        <ng-container *ngIf="(shift.activities)?.length">

            <span class="subtitle">Turno {{ shift?.shiftName }}</span>

            <ml-ui-care-plan-activity *ngFor="let activity of shift.activities" [activity]="activity"
                (activityClick)="onActivityClick($event)">
            </ml-ui-care-plan-activity>

        </ng-container>

    </div>

</div>


<ng-template #empty>
    <h2>Nenhum registro encontrado.</h2>
</ng-template>

<ng-template #loading>
    <!-- <ui-loading-spinner></ui-loading-spinner> -->
    <span>Carregando...</span>
</ng-template>