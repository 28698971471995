import { SvgIconService } from '@medlogic/shared/material';
import { Component, Input, OnInit } from '@angular/core';
import{ IProductCodeableConcept  } from '@medlogic/shared/shared-interfaces';
import {MatDialogModule, MatDialogRef, MatDialog} from '@angular/material/dialog';
import { UiDialogAlertComponent } from '@medlogic/shared/ui/dialog/ui-dialog-alert';


@Component({
  selector: 'ml-ui-care-plan-general-indicator',
  templateUrl: './ui-care-plan-general-indicator.component.html',
  styleUrls: ['./ui-care-plan-general-indicator.component.css']
})
export class UiCarePlanGeneralIndicatorComponent implements OnInit {

  @Input() patientsIcons : IProductCodeableConcept[] | null = [];

  
  constructor(
    svgIconSrv: SvgIconService,
    private matDialog: MatDialog,
    ) {  }

  ngOnInit() {
    
   }

  alertaIcone(description: string): void{

    this.matDialog.open(UiDialogAlertComponent, {
          width: '300px',
          maxWidth: '520px',
          height: '250px',
          data: {
            messageHtml: `<h2>${description}</h2>`,
          }
   })
  }

}
