import { IFhirPatient } from '@medlogic/shared/shared-interfaces';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IFhirPatientState } from './patient-state';
import * as FhirPatientActions from './patient.actions';

export const patientsFeatureKey = 'fhir-patient';


export const adapterFhirPatient: EntityAdapter<IFhirPatient> = createEntityAdapter<IFhirPatient>({
  selectId: instance => instance.patientIdentifier,
  sortComparer: sortOnOcurrencyAndNames
});

export const initialStateFhirPatient: IFhirPatientState = adapterFhirPatient.getInitialState({
  // additional entity state properties
  error: undefined,
  selectedId: null,
});


// FIXME: Falta incluir regras de negócio de ordenação para os alertas
function usersSortFn(a: IFhirPatient, b: IFhirPatient) {
  return a?.patientName.localeCompare(b?.patientName);
}

function sortOnOcurrencyAndNames(a: IFhirPatient, b: IFhirPatient) {
  if (a.active === b.active) {
    return a.patientName < b.patientName ? -1 : 1;
  } else {
    return a.active ? -1 : 1;
  }
}

export const reducer = createReducer(
  initialStateFhirPatient,
  on(FhirPatientActions.addFhirPatient,
    (state, action) => adapterFhirPatient.addOne(action.patient, state)
  ),
  on(FhirPatientActions.setFhirPatient,
    (state, action) => ({ ...state, selectedIdentifier: action.selectedId })
  ),
  on(FhirPatientActions.upsertFhirPatient,
    (state, action) => adapterFhirPatient.upsertOne(action.patient, state)
  ),
  on(FhirPatientActions.addFhirPatients,
    (state, action) => adapterFhirPatient.addMany(action.patients, state)
  ),
  on(FhirPatientActions.upsertFhirPatients,
    (state, action) => adapterFhirPatient.upsertMany(action.patients, state)
  ),
  on(FhirPatientActions.updateFhirPatient,
    (state, action) => adapterFhirPatient.updateOne(action.patient, state)
  ),
  on(FhirPatientActions.updateFhirPatients,
    (state, action) => adapterFhirPatient.updateMany(action.patients, state)
  ),
  on(FhirPatientActions.deleteFhirPatient,
    (state, action) => adapterFhirPatient.removeOne(action.id, state)
  ),
  on(FhirPatientActions.deleteFhirPatients,
    (state, action) => adapterFhirPatient.removeMany(action.ids, state)
  ),
  on(FhirPatientActions.loadFhirPatientsSuccess,
    // upsertMany é problemático, pois, caso haja pacientes excluídos, permaneceriam
    // (state, action) => adapterFhirPatient.upsertMany(action.patients, state)
    (state, action) => adapterFhirPatient.setAll(action.patients, state)
  ),
  on(FhirPatientActions.patientFail,
    (state, action) => ({
      ...state,
      error: action?.error
    })
  ),
  on(FhirPatientActions.clearFhirPatients,
    state => initialStateFhirPatient
  ),
  on(FhirPatientActions.clearSelectedFhirPatient,
    state => ({ ...state, selectedIdentifier: null })
  ),
  on(FhirPatientActions.loadFhirPatients,
    state => ({ ...state })
  ),
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapterFhirPatient.getSelectors();


export const getSelectedId = (state: IFhirPatient) => state.patientIdentifier;