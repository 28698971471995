import { SvgIconService } from '@medlogic/shared/material';
import { Component, OnInit } from '@angular/core';
import { selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { AppMedlogicPwaCuidadoState } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'ml-ui-care-plan-practitioner',
  templateUrl: './ui-care-plan-practitioner.component.html',
  styleUrls: ['./ui-care-plan-practitioner.component.css']
})
export class UiCarePlanPractitionerComponent implements OnInit {

  selectedPractitioner$ = this.store.pipe(select(selectSelectedLogin));

  constructor(svgIconSrv: SvgIconService,
    private store: Store<AppMedlogicPwaCuidadoState>) { }

  ngOnInit() {
  }


}
