import { LogService, IActionEmitter, EnActionType } from '@medlogic/shared/shared-interfaces';
import { SvgIconService } from '@medlogic/shared/material';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ml-ui-care-plan-footer',
  templateUrl: './ui-care-plan-footer.component.html',
  styleUrls: ['./ui-care-plan-footer.component.css']
})
export class UiCarePlanFooterComponent implements OnInit {

  @Input() showAdd: boolean = false;
  @Input() showConfirm: boolean = false;
  @Output() actionClick = new EventEmitter<IActionEmitter>();

  ENACTIONTYPE = EnActionType;

  constructor(
    svgIconSrv: SvgIconService,
    private log: LogService) { }

  ngOnInit() {
  }

  onActionClick(actionType: EnActionType, data?: any): void {
    try {
      this.actionClick.emit({ actionType, data });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }


}
